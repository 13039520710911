<template>
  <div class="main-page">
    <div class="news-detail container">
      <div class="flexRow news-detail-wrapper">
        <div class="detail-left">
          <div class="lg-box">
            <div class="flexRow lg-top">
              <div class="lg-text-img">
                <img src="@/assets/logonormal.jpg" alt="">
              </div>
              <div class="lg-text">
                <p>{{ news.publishDateStr }}</p>
                <p>{{ news.authorName }}</p>
              </div>
            </div>
            <!-- <img :src="news.image" alt="" class="detail-qrcode" /> -->
            <img src="@/assets/barcode.png" alt="" class="detail-qrcode" />
            <p class="lg-text-bottom">宣传合作请联系</p>
            <p class="lg-text-bottom">zhouq@pgy.space</p>
          </div>
        </div>
        <div class="detail-right">
          <div class="detail-content">
            <div class="detail-title">
              {{ news.title }}
            </div>
            <div class="detail-number">
              <span>{{ news.subtitle }}</span>
              <span>阅读 {{ news.views }}</span>
            </div>
            <div class="txt">
              <div class="ql-editor ql-snow" v-if="news">
                <div v-html="news.content"></div>
              </div>
            </div>
          </div>
          <!-- <div class="detail-btns flexRow">
            <a
              class="detail-btn-prev"
              :href="`/active/detail/?id=${Number(id) - 1}`"
            ></a>
            <a
              class="detail-btn-next"
              :href="`/active/detail/?id=${Number(id) + 1}`"
            ></a>
          </div> -->
          <div class="detail-more-list">
            <div class="hot-bottom-news">
              <div class="hot-tite">热门</div>
              <ul>
                <li v-for="item in hotList" :key="item.id">
                  <a :href="`/active/detail/?id=${item.id}`" class="flexRow">
                    <div class="time">
                      {{ item.publishDateStr }}
                    </div>
                    <div class="text overflowOne">
                      <span>热门</span>{{ item.title }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newNumber } from '@/api/home'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    const news = ref({})
    const hotList = ref([])
    const id = router.currentRoute.value.query.id
    console.log(id)
    onMounted(async () => {
      getNewNumber(id)
    })
    const getNewNumber = async () => {
      const params = {
        id: id
      }
      const data = await newNumber(params)
      console.log(data)
      news.value = data.data.news
      hotList.value = data.data.hotNews
    }
    return {
      hotList,
      news,
      id
    }
  },
  methods: {}
})
</script>

<style lang="scss" scoped>

.hot-bottom-news ul li:hover{
  background-color: #F7F7F7;
}
::v-deep p{
    img{
    width: 100% !important;
  }
}
.news-detail {
  padding: 150px 0;
}

.news-detail .news-detail-wrapper {
  justify-content: space-between;
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.news-detail .news-detail-wrapper .detail-left {
  width: 375px;
  text-align: left;
  position: relative;
}
.news-detail .news-detail-wrapper .detail-left .lg-box {
  width: 150px;
  margin-top: 210px;
  .lg-text-bottom{
    font-size: 18px;
    font-weight: 800;
    color: #000000;
    line-height: 25px;
  }
}
.news-detail .news-detail-wrapper .detail-left .lg-box .lg-top {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
}

.news-detail .news-detail-wrapper .detail-left .lg-box .lg-top .lg-logo {
  width: 60px;
  height: 60px;
  margin-right: 8px;
}
.lg-text-img{
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  img{
    height: 100%;
    width: 100%;
  }
}
.lg-text {
  line-height: 20px;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-left: 12px;
}
.lg-text:nth-child(2){
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-detail .news-detail-wrapper .detail-left .lg-box .detail-qrcode {
  width: 150px;
  height: 150px;
  margin-bottom: 64px;
}
.news-detail .news-detail-wrapper .detail-right {
  width: 0;
  text-align: left;
  margin-left: 20px;
  flex: 1;
}
.news-detail .news-detail-wrapper .detail-right .detail-content .detail-title {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 15px;
}
.news-detail .news-detail-wrapper .detail-right .detail-content .detail-number {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 95px;
}
.news-detail
  .news-detail-wrapper
  .detail-right
  .detail-content
  .detail-number
  span:first-child {
  font-weight: 700;
  color: #f2c000;
  // margin-right: 60px;
}
.news-detail .news-detail-wrapper .detail-right .detail-content .txt {
  line-height: 34px;
  font-size: 14px;
  margin-bottom: 100px;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.news-detail .news-detail-wrapper .detail-right .detail-btns {
  margin-bottom: 105px;
}

.news-detail .news-detail-wrapper .detail-right .detail-btns a.detail-btn-prev {
  margin-right: 45px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAABR0lEQVQ4T7XUyU7CUBTG8f9tYkLlom9C3TnghD6CiVsw4LA3ce1zqKhx2JM4oE8hRVY+B3cn95g20FaZm9j9r9/t6XeuYoZH/OwuwhlzZk9N68TXO4h9AjUPnE4FpamLEKIsqAu8zslEKC29hbUvIVLqknznWClkLJSW3sTa1x66It85ClDweSOh+JkNxAmQRlEjbw77aCSUZmYdnEaI4Ia6qapzbHKQA4nSdgt0nQZCDrilbip/0UCifLprdJ03IIdwx5I5UOp3Uj81SpSWu4oN0QJwj2fKo1CUKL67gjjvPfSAZ0rjUAjFd5d7aBF45MuU1D7dSY1KD4M3pzpq/0iphhPhNL8jwh9uASdRAM9Uhk14aFdTVS5KTpYcrvFMdWLJ44El1gpqePGG/M8ixwPT2yj7PNPVEeG2LvId4ukvq3hg8fX4Az/FqG+218BhAAAAAElFTkSuQmCC)
    no-repeat 0 0;
  background-size: 100% 100%;
}

.news-detail .news-detail-wrapper .detail-right .detail-btns a {
  display: block;
  width: 14px;
  height: 24px;
}
.news-detail .news-detail-wrapper .detail-right .detail-btns a.detail-btn-next {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAYCAYAAADKx8xXAAABSElEQVQ4T53Uy07CQBTG8f8Z3JXncIUUnkJASNx7ifcH8Alcm7hX0ahbEx/B+3UJJj6IWiyJSY/pWKHIpYVup7/MZOb7jugbWb6dC4RdcVuXpPxEG842sAf6hZiauN5VGiuqCM3sPugWaAtMTQredRKW8AeLX7MHqG5abMyc5L3bUdjCGD5EdSPCFcl7d8NwB3axU0dZB/WQoCJu+34Q7oEW72CYd46AVYsJylJoP/zHfTCGj4EVhE8yQVly/mMcD4TRsQ0N5wRhGSwuyYz/9IeHwg5uOqfAEvCBCUqS95/DtZEwhs+ARYslmBXXf0mEFp+TYdoJ8QLwbnFSQiaGqhiadrf0R43QeJdj0bjPEe3UDUAQlKWYEICJIvfbTZvTtdQhj1AdbDPCTibXaqIi940ONVUpejdJwegdVlOmKrnkeWNDPul4/AHxrcA5+qYTGQAAAABJRU5ErkJggg==)
    no-repeat 0 0;
  background-size: 100% 100%;
}
.news-detail .news-detail-wrapper .detail-right .detail-btns a {
  display: block;
  width: 14px;
  height: 24px;
}
.hot-bottom-news ul li a {
  height: 140px;
  align-items: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAQCAYAAABOZ8wCAAAAAXNSR0IArs4c6QAAATRJREFUSA3tlrFKA0EQhv+5JIZ4ezaidoIWFop5A7Gw0ScQQdNIIJWVCDb6EtaCWPkcthI8jpAipWWQELhE0eV+J0WCEA65che32WVmtvj++WdZ4H/5pQDbi0eMo/M8qiAv4Xw84DvAKybmwnmWogBMwjpj02UcXha963w922abr6aj8NfOwxQFYDfaUvhEu39b9K7z9YzXVhX8WQW4mcCIWuAAJdmHdZ7tD4DMQmRTi85APPj7qs/JEJRAvml4oP3ek7m8xwG1+Z16fB0BmmWPOWdo7KGKj/AeGZch5RPZHQ68t7qau4axeUSGJdiFY6kP1epq9pksHh7YgcG3QusXDpVaQ3b66RTT246zZ1Zgwyed5y/Y9PQ39ATe3xkf8VD93EeUtmQDn9NOe7/zBRUyf5R/ADSsXzYhVy80AAAAAElFTkSuQmCC)
    no-repeat 100%;
}

.hot-bottom-news ul li a .time {
  font-size: 14px;
  width: 180px;
}

.hot-bottom-news ul li a .text {
  flex: 1;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  padding-right: 80px;
}

.hot-bottom-news ul li a .text span {
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  width: 34px;
  height: 22px;
  background: #ffd00b;
  line-height: 22px;
  text-align: center;
  margin-right: 10px;
}
.hot-bottom-news ul li {
  border-bottom: 1px solid hsla(0, 0%, 77.6%, 0.15);
  padding: 0 40px;
  transition: all ease .6s;
}
.hot-bottom-news .hot-tite {
  font-size: 16px;
  color: #f2c000;
  margin-bottom: 20px;
}

@media (max-width: 756px) {
  .lg-box{
  width: 100%;
  }
  .lg-top {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  }
  .lg-text:nth-child(2){
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  .hot-bottom-news ul li:hover{
  background-color: #F7F7F7;
  }
  .news-detail {
    padding: 10px 0;
  }
  .news-detail-wrapper {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .detail-left {
    // display: none;
  }
  .news-detail .news-detail-wrapper .detail-right .detail-content .txt {
    line-height: 34px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .news-detail .news-detail-wrapper .detail-right .detail-btns {
    margin-bottom: 50px;
  }
  .hot-bottom-news ul li a .time {
    display: block;
    width: 100px;
  }
  .news-detail .news-detail-wrapper .detail-right {
    width: 100%;
    text-align: left;
    margin-left: 0px !important;
    padding: 10px;
  }
  .hot-tite {
    margin-bottom: 15px;
  }
  .news-detail
    .news-detail-wrapper
    .detail-right
    .detail-content
    .detail-number {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .detail-number span:nth-child(2){
    text-align: right;
  }
  .news-detail .news-detail-wrapper .detail-left .lg-box {
    width: 100%;
    margin-top: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
</style>
